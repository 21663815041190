import React from 'react';
import SEO from '../../structure/seo';
import Layout from '../../structure/layout';
import Index from '../../structure/pages/neighborhood/canal/index';


const TheCanal = () => {
    return (
        <Layout shadowNav={false}>
            <SEO title={'El Canal'} />
            <Index />
        </Layout>
    );
}

export default TheCanal;