import React, { useState } from 'react';
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components";
import { Container, Form, Col, InputGroup, Row, Button } from 'react-bootstrap';

const ContainerTitle = styled.section`
    background-color: #1E2E48;
    padding:2.5% 2.5%;
    margin-top: 7rem;
`
const P = styled.p`
    text-align: justify;
    color:#fff;
    padding-top: 2%;

`
const H2 = styled.h2`
    text-align: center;
    color:#fff;
    font-size: 3rem;
`
const H3 = styled.h3`
    text-align: center;
    color:#C1292F;
    font-size: 1.2rem;
`

const Index = () => {
    return (
        <>
            <ContainerTitle>
                <H2>El Canal</H2>
            </ContainerTitle>
            <Container>
                <nav aria-label="breadcrumb" className="mt-5">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a style={{ color: "#000", textDecoration: "none" }} href="/">INICIO</a></li>
                        <li className="breadcrumb-item"><a style={{ color: "#000", textDecoration: "none" }} href="/">BARRIOS</a></li>
                        <li className="breadcrumb-item active" aria-current="page">EL CANAL</li>
                    </ol>
                </nav>
                <Row className="justify-content-center">
                    <Col lg={8} className="text-center">
                        <StaticImage src="../../../../images/logo-02.png" />
                        <H2 style={{ color: "#1E2E48" }} className="mt-5">Sobre Costa del Río Paraná y canales navegables al río.</H2>
                        <H3>EN ALTURAS NORMALES DEL RIO</H3>
                    </Col>
                </Row>
                <Row className="justify-content-center mt-5">
                    <Col lg={4} className="text-justify">
                        <P className="text-dark"><strong>Característica:</strong> Barrio abierto. Destacado por encontrarse frente al Río Paraná y por contar con un canal navegable para embarcaciones de pequeño porte como por ejemplo; lanchas,motos de agua, kayak, etc</P>
                    </Col>
                    <Col lg={4} className="text-justify">
                        <P className="text-dark"><strong>Ubicación:</strong> Al final del camino principal de ingreso, se ubica el Barrio “El Canal del San  Juan” La ubicación es uno de los puntos más  fuertes que destacan quienes ya lo eligieron.</P>
                    </Col>
                    <Col lg={4} className="text-justify">
                        <P className="text-dark"><strong>Bienes de Infraestructura:</strong> Calle de acceso en perfecto  estado de mantenimiento, red de alumbrado  público y un importante canal de navegación para lanchas de mediano porte para que  cada propietario pueda instalar amarras en  sus lotes</P>
                    </Col>
                </Row>
                <StaticImage src="../../../../images/RioPanama.png" className="mt-3"/>
                <StaticImage src="../../../../images/RioPanamaTable.png" className="mt-3"/>
            </Container>
        </>
    );
}

export default Index;